.servicesSection {
    background-color: #f8f9fa;
    padding: 80px 0;
    text-align: center;
  }
  
  .servicesSection h2, .faqSection h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
  }
  
  .carouselContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .serviceSlide {
    padding: 15px;
  }
  
  
  .serviceContent {
    background-color: white;
    border-radius: 15px;
    padding: 20px; /* Reduce padding to decrease height */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    height: 450px; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    margin-left: 20px;
    margin-right: 40px;
  }
  
  
  .serviceContent:hover {
    transform: translateY(-10px);
  }
  
  .serviceIcon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .serviceContent h3 {
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .serviceContent p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    flex-grow: 1;
  }
  
  .bookNowButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 20px;
  }
  
  .bookNowButton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Customize slick dots */
  .slick-dots {
    bottom: -40px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #007bff;
  }
  
  .slick-dots li.slick-active button:before {
    color: #0056b3;
  }

  .slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slick-prev::before,
.slick-next::before {
  font-family: 'slick';
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: #007bff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 30px;
  }

  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }
  
  .serviceContent {
    margin-left: 15px;
    margin-right: 15px;
  }
}

  

  @media (max-width: 1024px) {
    .servicesSection h2, .faqSection h2 {
      font-size: 2rem;
    }
    .serviceContent {
      height: 450px;
    }
  }
  
  @media (max-width: 768px) {
    .servicesSection h2, .faqSection h2 {
      font-size: 1.8rem;
    }
    .serviceContent {
      height: 500px;
    }
    .serviceContent h3 {
      font-size: 1.2rem;
    }
    .serviceContent p {
      font-size: 0.9rem;
    }
    .serviceContent {
      margin-right: 40px;
    }
  }

  