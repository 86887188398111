.heroSection {
    background-color: #ffffff;
    padding: 20px 5%;
    min-height: 80vh;
    display: flex;
    align-items: center;
}

.heroContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.heroText {
    flex: 1;
    padding-right: 40px;
}

#heroHeading {
    font-family: 'Arial', sans-serif;
    font-size: 3rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
    line-height: 1.2;
}

.heroSubtext {
    font-size: 1.2rem;
    color: #666666;
    margin-bottom: 30px;
    line-height: 1.5;
}

.ctaButton {
    padding: 12px 24px;
    font-size: 1.1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ctaButton:hover {
    background-color: #0056b3;
}

.heroImageContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.heroImg {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 968px) {
    .heroContent {
        flex-direction: column;
        text-align: center;
        margin-top: 20%;
    }

    .heroText {
        padding-right: 0;
        margin-bottom: 40px;
    }

    #heroHeading {
        font-size: 2.5rem;
    }

    .heroImageContainer {
        justify-content: center;
    }
}

@media (max-width: 600px) {
    #heroHeading {
        font-size: 2rem;
    }

    .heroSubtext {
        font-size: 1rem;
    }
}

/*About Section*/
.aboutSection {
    background-color: #f8f9fa;
    padding: 20px 5%;
    min-height: 80vh;
    display: flex;
    align-items: center;
}

.aboutContent {
    display: flex;
    flex-direction:row-reverse;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.aboutImageContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    margin-right: 40px; /* Adjust margin as needed */
}

.aboutImage {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.aboutText {
    flex: 1;
    text-align: justify;
}

.aboutText h2.visionHeading {
    font-family: 'Arial', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
    text-align: center; /* Center align "Our Vision" in mobile view */
}

.aboutText p {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: justify;
}

@media (max-width: 968px) {
    .aboutContent {
        flex-direction: column;
        text-align: center;
    }

    .aboutImageContainer {
        justify-content: center;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .aboutText h2.visionHeading {
        font-size: 2rem;
        text-align: center; /* Center align "Our Vision" in mobile view */
    }
}

@media (max-width: 600px) {
    .aboutSection {
        padding: 60px 5%;
    }

    .aboutText h2.visionHeading {
        font-size: 1.8rem;
    }

    .aboutText p {
        font-size: 0.9rem;
    }
}



/*Founder Section*/

.founderSection {
    background-color: #ffffff;
    padding: 80px 5%;
    min-height: 80vh;
    display: flex;
    align-items: center;
}

.founderContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
}

.founderImageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: 40px;
}

.founderImage {
    max-width: 250px; /* Adjust size as needed */
    width: 100%;
    height: auto;
    border-radius: 50%; /* Make image circular */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.founderText {
    flex: 2;
}

.founderInfo{
    text-align: justify;
}

.founderText h2 {
    font-family: 'Arial', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.founderText h3 {
    font-family: 'Arial', sans-serif;
    font-size: 1.8rem;
    color: #666666;
    margin-bottom: 20px;
}

.founderText p {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
    line-height: 1.6;
}

@media (max-width: 968px) {
    .founderContent {
        flex-direction: column;
        text-align: center;
    }

    .founderImageContainer {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .founderImage {
        max-width: 200px; /* Adjust size for smaller screens */
    }

    .founderText h2 {
        font-size: 2rem;
    }

    .founderText h3 {
        font-size: 1.5rem;
    }
}

@media (max-width: 600px) {
    .founderSection {
        padding: 60px 5%;
    }

    .founderText h2 {
        font-size: 1.8rem;
    }

    .founderText h3 {
        font-size: 1.3rem;
    }

    .founderText p {
        font-size: 0.9rem;
    }
}

