.disclaimer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 101;
  }
  
  .disclaimer-content {
    background-color: white;
    border-radius: 0.5rem;
    width: 95%;
    max-width: 28rem;
    height: 60vh; /* Increased height for mobile */
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  
  .disclaimer-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1e40af;
    margin-bottom: 1rem;
  }
  
  .disclaimer-text {
    color: #1e3a8a;
    margin-bottom: 1rem;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .disclaimer-list {
    list-style-type: disc;
    padding-left: 1.25rem;
    margin-top: 0.5rem;
  }
  
  .disclaimer-list li {
    margin-bottom: 0.25rem;
  }
  
  .disclaimer-button {
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 1rem;
  }
  
  .disclaimer-button:hover {
    background-color: #1d4ed8;
  }
  
  /* Desktop styles */
  @media (min-width: 768px) {
    .disclaimer-content {
      max-width: 42rem; /* Increased width for desktop */
      height: auto;
      max-height: 80vh;
    }
  
    .disclaimer-text {
      max-height: 50vh;
    }
  }