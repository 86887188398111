.Navbar {
    background-color: #ffffff !important;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 6px 0 6px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #333333;
    box-shadow: 0px 2px 4px 0px #0000000A;
    position: fixed;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.insuranceClaimLogo {
    margin-left: 2vw;
    margin-top: -2px;
}

.menuToggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    color: #333333;
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
}


.NavItems {
    display: none;
}

#servicesMenu {
    margin-top: -4px;
}
@media (max-width: 1023px) {
    .menuToggle {
        display: block;
    }
    .NavItems.show {
        display: block;
        position: absolute;
        top: 46px;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0px 2px 4px 0px #0000000A;
        animation: slideDown 0.5s ease-out;
        list-style: none;
        padding: 0px;
    }

    @keyframes slideDown {
        from {
            transform: translateY(-30%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .NavItems > li {
        padding: 15px 20px;
        border-bottom: 1px solid #f0f0f0;
    }

    .NavItems > li:last-child {
        border-bottom: none;
    }

    #servicesMenu {
        cursor: pointer;
    }

    .dropdown-menu {
        display: none;
        padding-left: 20px;
        margin-top: 10px;
    }

    .dropdown-menu.show {
        display: block;
        animation: fadeIn 0.3s ease-out;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    #contactButton {
        width: 75%;
        border-radius: 16px;
        padding: 8px 19px;
        background-color: #007bff;
        color: white;
        text-decoration: none;
        transition: background-color 0.3s ease;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        margin-left: 10vw;
    }
}
.servicesDropDownMenuItems {
    list-style-type: none;
    font-weight: 500;
    color: #333333;
    padding: 8px 0;
}
/* FOR BIG SCREENS */
@media (min-width: 1024px) {
    .Navbar {
        height: 50px;
        justify-content: space-between;
        position: fixed;
        z-index: 999;
    }

    .NavItems {
        display: flex;
        list-style-type: none;
        margin-right: 100px;
        top: 62px;
    }

    .NavItems > li, .NavItems > li > a {
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.3s ease;
        position: relative;
    }

    .NavItems > li:hover {
        color: #007bff;
    }

    .dropdown-menu > li:hover {
        transition: color 0.3s ease;
        background-color: #ebebeb;
    }

    .insuranceClaimLogo {
        margin-left: 10vw;
        height: 60px;
        margin-top: -5px;
    }

    #hamburgerMenu {
        display: none;
    }

    #contactButton {
        margin-left: 15px;
        border-radius: 16px;
        padding: 8px 19px;
        background-color: #007bff;
        color: white;
        text-decoration: none;
        transition: background-color 0.3s ease;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        cursor: pointer;
        height: 34px;
        margin-top: -8px;
        border: none;
    }

    .dropdown-icon {
        font-size: 20px;
        margin-left: -20px;
        margin-bottom: 5px;
        transition: color 0.3s ease;
    }

    #contactButton:hover {
        background-color: #0056b3;
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        background-color: #ffffff;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        min-width: 160px;
        padding: 12px 16px;
        border-radius: 8px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .servicesDropDownMenuItems {
        list-style-type: none;
        font-weight: 500;
        color: #333333;
        padding: 8px 0;
    }

    #servicesMenu:hover .dropdown-menu {
        display: block;
        opacity: 1;
    }
}