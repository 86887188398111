.contact-form-container {
    max-width: 500px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
  }
  @media screen and (min-width: 768px) {
    .contact-form-container {
      margin-left: 20px;
      border: solid 1px #c2c2c2;
    }
  }
  
  /* Mobile view */
  @media screen and (max-width: 767px) {
    .contact-form-container {
      margin: 40px 0 0 0;
    }
  }
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  input,
  select,
  textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  textarea {
    height: 80px;
    resize: vertical;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }

  #policyType{
    width: 100%;
  }